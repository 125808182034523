// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Inter", "Segoe UI",
		"Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
		"Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

.loading-div {
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
}

.spiner {
	height: 55px;
	width: 55px;
	border-top: 3px solid purple;
	border-left: 3px solid purple;
	border-radius: 50%;
	animation: spin 1s linear infinite;
}

@keyframes spin {
	0% {
		transform: rotate(0deg); /* Initial rotation */
	}
	100% {
		transform: rotate(360deg); /* Full rotation */
	}
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;CACC,SAAS;CACT;;8BAE6B;CAC7B,mCAAmC;CACnC,kCAAkC;AACnC;;AAEA;CACC;WACU;AACX;;AAEA;CACC,YAAY;CACZ,aAAa;CACb,aAAa;CACb,uBAAuB;CACvB,mBAAmB;AACpB;;AAEA;CACC,YAAY;CACZ,WAAW;CACX,4BAA4B;CAC5B,6BAA6B;CAC7B,kBAAkB;CAClB,kCAAkC;AACnC;;AAEA;CACC;EACC,uBAAuB,EAAE,qBAAqB;CAC/C;CACA;EACC,yBAAyB,EAAE,kBAAkB;CAC9C;AACD","sourcesContent":["body {\n\tmargin: 0;\n\tfont-family: -apple-system, BlinkMacSystemFont, \"Inter\", \"Segoe UI\",\n\t\t\"Roboto\", \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\",\n\t\t\"Helvetica Neue\", sans-serif;\n\t-webkit-font-smoothing: antialiased;\n\t-moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n\tfont-family: source-code-pro, Menlo, Monaco, Consolas, \"Courier New\",\n\t\tmonospace;\n}\n\n.loading-div {\n\twidth: 100vw;\n\theight: 100vh;\n\tdisplay: flex;\n\tjustify-content: center;\n\talign-items: center;\n}\n\n.spiner {\n\theight: 55px;\n\twidth: 55px;\n\tborder-top: 3px solid purple;\n\tborder-left: 3px solid purple;\n\tborder-radius: 50%;\n\tanimation: spin 1s linear infinite;\n}\n\n@keyframes spin {\n\t0% {\n\t\ttransform: rotate(0deg); /* Initial rotation */\n\t}\n\t100% {\n\t\ttransform: rotate(360deg); /* Full rotation */\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
