// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.errorPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.errorComment {
  font-family: Inter;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: center;
  color: #ba1e12;
  margin-top: 10px;
}
.infoMsg {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2% 3%;
  gap: 20px;
}

.textInfo {
  font-size: 0.9rem;
  color: rgba(39, 105, 135, 0.9);
  line-height: 1.6;
  text-align: center !important;
  @media (max-width: 600px) {
    font-size: 0.75rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/NoRoleAssigned/NoRoleAssigned.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;AACzB;AACA;EACE,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;EACnB,kBAAkB;EAClB,cAAc;EACd,gBAAgB;AAClB;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,cAAc;EACd,SAAS;AACX;;AAEA;EACE,iBAAiB;EACjB,8BAA8B;EAC9B,gBAAgB;EAChB,6BAA6B;EAC7B;IACE,kBAAkB;EACpB;AACF","sourcesContent":[".errorPage {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n}\n.errorComment {\n  font-family: Inter;\n  font-size: 16px;\n  font-weight: 300;\n  line-height: 24px;\n  letter-spacing: 0px;\n  text-align: center;\n  color: #ba1e12;\n  margin-top: 10px;\n}\n.infoMsg {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding: 2% 3%;\n  gap: 20px;\n}\n\n.textInfo {\n  font-size: 0.9rem;\n  color: rgba(39, 105, 135, 0.9);\n  line-height: 1.6;\n  text-align: center !important;\n  @media (max-width: 600px) {\n    font-size: 0.75rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
